.accordion {
  padding: 0px;
  margin: 0px;
  width: 100%;
  margin-bottom: 4px;
  &.MuiAccordion-root {
    border-radius: 0px;
    box-shadow: 0px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05),
      0 0px 5px 0 rgba(0, 0, 0, 0.1);
    &::before {
      background: none;
    }
    &.Mui-expanded {
      margin: 0px 0px 4px;
    }
  }
  .MuiAccordionSummary-root {
    padding: 0px;
    min-height: unset;
    &.Mui-expanded {
      min-height: unset;
    }
    .MuiAccordionSummary-content {
      margin: 0px;
      .core-list {
        padding-bottom: 0px;
        width: 100%;
        .MuiListItem-root {
          box-shadow: unset;
          background: unset;
          margin: unset;
        }
      }
    }
    .MuiButtonBase-root {
      padding: 0px;
      margin-right: 14px;
      // color: #212121;
    }
  }
  .MuiAccordionDetails-root {
    padding: 4px 0px 0px;
    margin-bottom: 4px;
    flex-wrap: wrap;
  }
}
