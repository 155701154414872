.room-type-detail-img {
  .MuiAvatar-root {
    width: 100%;
    max-width: 85%;
    min-height: 100px;
    border-radius: 4px;
  }
}
.room-type-detail {
  .MuiAvatar-root {
    width: 15px;
    padding: 4px;
    height: 15px;
    background: #e5edff;
  }
  .align-center {
    align-items: center;
  }
  .MuiSvgIcon-root {
    color: #5367ff;
    font-size: 15px;
  }
}

.key-card-wrapper {
  width: 100%;
  height: 80vh;
  background-image: linear-gradient(#7f7fd5, #91eae4);
  color: white;
  .bottom-img {
    position: fixed !important;
    right: 1px;
    bottom: 60px;
  }
}

.hotel-packages-card {
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  .badge-slider {
    position: absolute;
    .MuiBadge-badge {
      border-radius: 3px;
      width: 30px;
    }
    .MuiBadge-anchorOriginTopRightRectangle {
      top: 0;
      transform-origin: 100% 0%;
      right: unset;
      transform: scale(1) translate(0%, -0%);
    }
  }

  .add-button {
    // background: #2f53e6;
    background-color: #0019d8;
    color: white;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    min-width: 20px;
  }
}
.checkin-card {
  border-radius: 3px;
  background-image: linear-gradient(#2c57e6, #8e5c9d);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  color: white;
  margin-bottom: 10px;
}

//! New Design 23/12/2020
.booking-search .MuiCard-root {
  background: #e3e9ff;
}
.services-card {
  padding: 12px;
}
