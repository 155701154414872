.room-billing {
  width: 100%;
  display: flex;
  .MuiListItemIcon-root {
    min-width: 30px;
  }
  .MuiTypography-body2 {
    display: flex;
  }
  .MuiTypography-colorTextSecondary {
    color: #454545;
  }
}
.hotel-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // overflow: hidden;
  .grid-list {
    width: 100%;
  }
  .MuiGridListTile-root {
    height: 200px !important;
    cursor: pointer;
  }
  .MuiGridListTile-tile {
    border-radius: 4px;
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiGridListTile-imgFullHeight {
    width: 100%;
    height: 50%;
    object-fit: cover;
  }
  .MuiGridListTileBar-root {
    background-color: white;
    display: block;
    // padding: 6px;
    height: auto;
  }
  .MuiGridListTileBar-title {
    padding: 6px;
  }
  .MuiGridListTileBar-subtitle {
    border-top: 1px solid #eeeeee;
    padding: 6px;
  }
  .MuiGridListTileBar-titleWrap {
    margin-left: 0px;
    margin-right: 0px;
    color: #454545;
  }
}

.room-packages-rate {
  .MuiListItem-root {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
  }
  .MuiListItemIcon-root {
    min-width: 20px;
  }
  .MuiTypography-body1 {
    line-height: 1;
  }
  .MuiTypography-body2 {
    line-height: 1;
  }
}
.hotel-gallery li:last-child img {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  filter: brightness(0.5);
  // opacity: 0.5;
  // display: none;
}
.hotel-gallery li:last-child .hotel-gallery-last {
  justify-content: center;
  color: white;
  text-align: center;
  padding: 0 20%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 35px;
  font-weight: 300;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
}

.booking-summary {
  width: 100%;
  .MuiListItemAvatar-root {
    min-width: 50px;
  }
  .MuiListItem-root {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .MuiTypography-body2 {
    line-height: 0.23;
  }
  .MuiAvatar-square {
    height: 10vh;
    width: 45px;
    border-radius: 3px;
  }
  .MuiListItemText-multiline {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MuiButton-root {
    background: #e5edff;
    color: #5367ff;
    padding: 0px;
    width: 25px;
    height: 25px;
    min-width: 25px;
  }
}
.reset-listitem {
  .MuiListItem-root {
    padding-left: 12px;
  }
  .MuiListItemIcon-root {
    min-width: 20px;
  }
  .MuiTypography-body1 {
    line-height: 1;
  }
  .MuiTypography-body2 {
    line-height: 1;
  }
}
