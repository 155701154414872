.home-search {
  margin: 0;
  // height: 50vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  height: 90vh;
  .search-filter {
    max-width: 400px;
    min-width: 280px;
    margin: 0 auto;
    width: 80vw;
    padding: 30px 12px;
    border-radius: 10px;
    display: block;
    // background-color: #00031d91;
    height: fit-content;
    .search {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
        margin-top: 0px;
      }
      .MuiFilledInput-adornedStart {
        background: white;
        border-bottom-left-radius: 4px;
      }
      .MuiFormControl-root {
        border-radius: 4px;
      }
      .MuiFilledInput-input {
        padding: 12px 12px 12px;
        background: white;
        // border-radius: 4px;
      }
    }
    .date-range {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      background: white;
      border-radius: 4px;
      padding: 6px;
      .date-picker {
        cursor: pointer;
        padding-left: 6px;
      }
      .month-year {
        margin-top: 10px;
        text-align: left;
        margin-left: 6px;
      }
    }
    .room-search {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      width: 100%;
      display: flex;
      .menu-select {
        .MuiMenuItem-root {
          background: red !important;
        }
      }
      .MuiListItem-root {
        width: 70%;

        background: white;
        border-radius: 4px;
        .MuiTypography-body2 {
          line-height: 15px;
        }
      }
      .MuiIconButton-edgeEnd {
        padding: 6px;
      }
    }

    .MuiSvgIcon-root {
      color: #212121;
    }
    .MuiListItemIcon-root {
      min-width: 40px;
    }
    .MuiListItemText-multiline {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .search-btn {
      margin-left: 6px;
      width: 30%;
    }

    .location-icon {
      color: white;
    }
  }
}
.home-carousel {
  width: 600px;
  display: block;
  margin-bottom: 12px;
  cursor: pointer;
  // .carousel-wrapper {
  // }
  .img-carousel {
    object-fit: cover;
    width: 100%;
    height: 15vh;
  }
  .promotions-carousel {
    min-height: 20vh;
    border-radius: 3px;
    margin-right: 10px;
    background: white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    .promotions-content {
      padding: 6px;
    }
    .divider {
      border-bottom: 1px solid #eeeeee;
    }
  }
}

.guest-app-header {
  min-height: 80px;
  .app-content {
    display: flex;
    width: 100%;
    margin-top: -3vh;
  }
  .MuiAvatar-root {
    width: 55px;
    height: 55px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiListItemText-root .MuiTypography-displayBlock {
    padding-left: 12px;
    color: white;
    font-weight: 400;
    line-height: 1;
  }
}
.tab {
  // padding: 8px;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 5;
}
// new Home screen
.app-header {
  background: linear-gradient(to right, #2f47ff, #2408ba);
  min-height: 85px;
  padding: 12px;
  .app-content {
    display: flex;
    width: 100%;
    margin-top: -4vh;
  }
  .MuiAvatar-root {
    width: 55px;
    height: 55px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiListItemText-root .MuiTypography-displayBlock {
    padding-left: 12px;
    color: white;
    font-weight: 400;
    line-height: 1;
  }
}
.nav-bar {
  background: white;
  margin: 0px 8px;
  display: flex;
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  // width: 90%;
  // max-width: 95%;
  margin: 0 auto;
  // margin-top: -5vh;
  // height: 10vh;
  .nav-label {
    white-space: nowrap;
  }
  .MuiGrid-root {
    text-align: center;
    // padding: 2px;
    font-size: 10px;
    cursor: pointer;
  }
  .MuiButtonBase-root {
    display: block;
  }
  .MuiButton-textPrimary {
    border-bottom: 2px solid #0019d8;
    border-radius: 0;
  }
  .MuiButtonBase-root.Mui-disabled {
    background-color: white;
  }
}

//! New Design Style 23/12/2020
.search-filter {
  border-radius: 30px;
  display: block;
  height: fit-content;
  .MuiIconButton-root {
    background: white;
    border-radius: 30%;
    padding: 10px;
  }
  .search-btn {
    border-radius: 12px;
    width: 100%;
  }
  .search {
    // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    //   0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
      margin-top: 0px;
    }
    .MuiFilledInput-adornedStart {
      background: white;
      border-bottom-left-radius: 4px;
    }
    .MuiFormControl-root {
      border-radius: 4px;
    }
    .MuiFilledInput-input {
      padding: 12px 12px 12px;
      background: white;
      // border-radius: 4px;
    }
  }
  .date-range {
    // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    //   0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background: white;
    border-radius: 12px;
    padding: 10px;
    .date-picker {
      cursor: pointer;
      padding-left: 6px;
    }
    .month-year {
      margin-top: 10px;
      text-align: left;
      margin-left: 6px;
    }
  }
  .room-search {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    width: 100%;
    display: flex;
    .menu-select {
      .MuiMenuItem-root {
        background: red !important;
      }
    }
    .MuiListItem-root {
      width: 70%;

      background: white;
      border-radius: 4px;
      .MuiTypography-body2 {
        line-height: 15px;
      }
    }
    .MuiIconButton-edgeEnd {
      padding: 6px;
    }
  }

  .MuiSvgIcon-root {
    color: #212121;
  }
  .MuiListItemIcon-root {
    min-width: 40px;
  }
  .MuiListItemText-multiline {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.guest-appbar {
  .MuiAppBar-colorPrimary {
    border-bottom-right-radius: 40%;
    border-bottom-left-radius: 40%;
  }
}
