.checkout-time-btn {
  border: solid 1px #e2e2e2 !important;
  border-radius: 3px !important;

  .MuiTypography-colorTextSecondary {
    color: #454545;
  }
}
.checkout-time {
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    .MuiTypography-colorTextSecondary {
      color: white;
    }
    color: white;
    background-color: #2f53e6 !important;
  }
}
.checkout-time-btn.MuiListItem-root {
  text-align: center;
  background: white;
  padding-top: 0px;
}
.new-order-services {
  display: block;
  margin: auto;
  .count {
    padding: 0px 4px;
  }
  .plus-btn {
    width: 15px;
    height: 15px;
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    border-radius: 50%;
    min-width: 15px;
    color: white;
    background-color: #0032ff;

    margin-right: 6px;
  }
  .minus-btn {
    width: 15px;
    height: 15px;
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    border-radius: 50%;
    min-width: 15px;
    margin-left: 6px;
    color: white;
    background-color: #c2c2c2;
  }
  .MuiIconButton-root.Mui-disabled {
    color: white;
    background-color: #c2c2c2;
  }
  .MuiIconButton-colorPrimary:hover {
    background-color: #0032ff;
  }
}

.select-button {
  width: 100%;
  background-color: #5367ff !important;
}

.register {
  display: flex;
  justify-content: center;
  width: 100%;
  .register-btn {
    border-radius: 100px;
    margin: 24px auto 0px;
    width: 100%;
    width: 180px;
    display: block;
    text-transform: capitalize;
  }
}

.slider-rating {
  .MuiSlider-markLabelActive {
    margin-left: 12px !important;
    font-size: 12px;
  }
  .MuiSlider-markLabel span {
    font-size: 12px;
    left: 90% !important;
  }

  @media (pointer: coarse) {
    .MuiSlider-root {
      padding: 0px;
    }
    .MuiSlider-markLabel span {
      font-size: 12px;
      left: 90% !important;
    }
    .MuiSlider-markLabel {
      top: 35px;
    }
    .MuiSlider-marked {
      margin-bottom: 8px;
    }
  }
}

.map-button {
  width: 35px;
  height: 35px;
  background-color: #5367ff !important;

  .MuiSvgIcon-root {
    color: white;
    width: 20px;
  }
}

.total-btn-wrapper {
  .MuiButton-root {
    color: white;
    width: 20px;
    height: 20px;
    min-width: 20px;
    padding: 10px;
  }
}

.checkin-scan {
  width: 100%;
  text-align: center;
  z-index: 1;
  position: fixed;
  background: #f5f5f5;
  bottom: 0;
  right: 0;
  padding-bottom: 12px;
  padding-top: 12px;
  // margin-bottom: 12px;
  //
  .MuiButton-root {
    width: 100%;
    max-width: 80%;
    border-radius: 20px;
    // background-color: #2f53e6;
  }
}

.fab-btn .MuiFab-root {
  border: 2px solid #cdd4f3;
}

.payment-btn {
  width: 100%;
  text-align: center;
  .MuiButton-root {
    width: 100%;
    max-width: 80%;
    border-radius: 20px;
    background-color: #2f53e6;
  }
}

.checkin-scanid-wrapper {
  .MuiButton-root {
    min-width: 45%;
    width: 45%;
    margin: 0px 8px;
  }
}
